import sniffer from 'sniffer'
import FastClick from 'fastclick'
import * as utils from './utils.js';

const devMode = true;
const body = document.querySelector('body');
const hero = document.querySelector('.hero');
var prllxRender;
let sy = 0;

class App {

  constructor(opt = {}) {

    window.scrollTo(0,0)
    console.log("%cCreated by wokine","color: #000; padding: 5px 0px;"),console.log("%chttp://wokine.com","color:#ccc")

    this.init()
    this.addEvents()
	this.initPrllx(body);

  }

  init() {


  	
	var videoExists = document.getElementById("video-container");

	if (videoExists != null) {
		
		(function () {
			var bv = new Bideo();
			bv.init({
				// Video element
				videoEl: document.querySelector('#background_video'),
	
				// Container element
				container: document.querySelector('#video_container'),
	
				// Resize
				resize: true,
				autoplay: true,
	
				// Array of objects containing the src and type
				// of different video formats to add
				src: [
					{
						//src: './videos/intro-supratec-syneo.mp4',
						//type: 'video/mp4'
					}
				],
	
				// What to do once video loads (initial frame)
				onLoad: function () {
				//document.querySelector('#video_cover').style.display = 'none';
				}
			});
		}());
	}






	FastClick(document.body);

    //removeLoading
    body.classList.remove('is-loading');

    $(".form__contact").submit(function(){
      $('body').css( 'cursor', 'wait');
      $("#submitFormContact").attr("disabled", true);
    });

  }

  addEvents() {

	history.scrollRestoration = 'manual';
    window.onbeforeunload = function() {window.scrollTo(0,0);}
	window.addEventListener('scroll', (e) => {
		sy = window.pageYOffset || document.documentElement.scrollTop;
	})

  }

  initPrllx(container) {

	  //default Parallax
	  const TLPrllxs = []
	  const prllxs = Array.from(container.querySelectorAll('*[data-prllx]'));
	  prllxs.forEach(item => {
		  let prllxTL = new TimelineMax({ paused: true});
		  TweenMax.set(item, { y: -1 * item.dataset.prllx });
  	      prllxTL.to(item, 1, { y:item.dataset.prllx, ease:Power0.easeNone });
		  TLPrllxs.push(prllxTL);
	  });

	  //Hero Visuel Parrallax
	  let heroTL = new TimelineMax({ paused: true});
	  heroTL.to(hero.querySelector('.hero__visuel'), 1, { y: "25%", ease:Power0.easeNone }, 0);
	  heroTL.to(hero.querySelector('.container'), 1, { y: "25%", ease:Power0.easeNone }, 0);

	  //PLaques Visuel Parrallax
	  const TLPLaquesPrllx = []
	  const plaquesPVisuelsPrllxs = Array.from(container.querySelectorAll('.plaque img'));
	  plaquesPVisuelsPrllxs.forEach(item => {
		  let prllxTL = new TimelineMax({ paused: true});
		  TweenMax.set(item, { x: "-55%" });
  	      prllxTL.to(item, 1, { x: "-45%", ease:Power0.easeNone });
		  TLPLaquesPrllx.push(prllxTL);
	  });

	  prllxRender = function (){

	    prllxs.forEach((item, index) => {
	      let norm = utils.clamp(utils.normalize(sy,  item.getBoundingClientRect().top + window.scrollY - window.innerHeight ,  item.getBoundingClientRect().top + window.scrollY + item.offsetHeight ), 0, 1);
		  TLPrllxs[index].progress(norm);
	    });

		let heroNorm = utils.clamp(utils.normalize(sy, 0 ,  hero.getBoundingClientRect().top + window.scrollY + hero.offsetHeight ), 0, 1);
		heroTL.progress(heroNorm);

		plaquesPVisuelsPrllxs.forEach((item, index) => {
	      let normPlaques = utils.clamp(utils.normalize(sy,  item.getBoundingClientRect().top + window.scrollY - window.innerHeight ,  item.getBoundingClientRect().top + window.scrollY + item.offsetHeight ), 0, 1);
		  TLPLaquesPrllx[index].progress(normPlaques);
	    });

	  }

	  TweenMax.ticker.addEventListener("tick", prllxRender, this, true, 1);

  }

}

const app = new App();
